<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/business_units">Business Units</a>
    </div>
    <div class="mb-2 d-block d-lg-none d-md-none">
      <div class="btn btn-sm btn-block form-control btn-primary p-2" v-if="['Admin'].includes(this.user.role)">
        <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Business Unit
      </div>
    </div>
    <div class="row list-actions">
      <div class="col left-column">
        <div class="list-tab active" v-on:click="getBUnits()">
          All<span>{{filteredBUnits.length}}</span>
        </div>
      </div>
      <div class="col right-column d-none d-lg-block d-md-block" v-on:click="newBUnit" v-if="['Admin'].includes(this.user.role)">
        <div class="btn btn-sm btn-primary">
          <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Business Unit
        </div>
      </div>
    </div>
    <SearchComponent
      @searchRecord="searchRecord($event)"
      :placeholder="'Search business units by name or code'"
      :showSideOptions="false"
      :isRealTime="true"
    ></SearchComponent>
    <ListComponent
      :records="filteredBUnits"
      :profile="'business_units'"
    ></ListComponent>
  </div>
</template>

<script>
  import SearchComponent from '../Search.vue'
  import ListComponent from '../List.vue'

  export default {
    components: {
      SearchComponent,
      ListComponent
    },
    data() {
      return {
        user: this.$userData.user,
        bunits: [],
        filteredBUnits: []
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getBUnits(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/bunits`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.bunits = response.data.bunits
          console.log(response.data)
          console.log(response.data.bunits)
          this.filteredBUnits = this.buildList(this.bunits)
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      searchRecord: function(search){
        this.filteredBUnits = this.buildList(this.bunits.filter(record => (
          (record.name ? record.name.toLowerCase().match(new RegExp(search.toLowerCase())) : false) ||
          (record.email ? record.email.toLowerCase().match(new RegExp(search.toLowerCase())) : false)
        )))
      },
      buildList: function(records){
        return records.map((record) => {
          return {
            key: record.code,
            header: record.name,
            subheader: `BU Code: ${record.code}`,
            indicators: [
              {
                key: `${record._id}_1`,
                text: record.isActive ? "Active" : "Inactive",
                background: record.isActive ? "primary-default" : "danger"
              }
            ],
            timestamp: {},
            record: record
          }
        })
      },
      newBUnit: function(){
        this.$router.push("/business_units/new")
      },
      validateUser: async function(){
       if(this.$userData.user.role !== 'Admin'){
         this.$alertify({
           group: 'notification',
           title: `Access denied`,
           type: 'warning',
           text: `You don't have access to this module.`
         })
         await this.$sleep(500);
         this.$router.push('/')
       }
      }
    },
    mounted: function() {
      this.validateUser();
      this.getBUnits();
    }
  }
</script>
